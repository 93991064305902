import Vue from 'vue'
import axios from '@axios'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const imageFields = ['logo', 'favicon', 'hero_img', 'footer_img']

const loadingData = {
  icon: null,
  title: 'New instance is building',
  subtitle: 'It will take a while',
  showFooter: false,
}
const loadingUpdateData = {
  icon: null,
  title: 'The Instance is updating',
  subtitle: 'It will take a while',
  showFooter: false,
}
const successData = {
  icon: 'CheckCircleIcon',
  iconVariant: 'primary',
  title: 'The instance successfully created',
  subtitle: 'Would you like to add users to this instance?',
  showFooter: true,
}
const successUpdateData = {
  icon: 'CheckCircleIcon',
  iconVariant: 'primary',
  title: 'The instance successfully updated',
  subtitle: '',
  showFooter: false,
}
const errorData = {
  icon: 'AlertCircleIcon',
  iconVariant: 'danger',
  title: 'The instance is not created',
  subtitle: 'Please have another try.',
  showFooter: false,
}
const errorUpdateData = {
  icon: 'AlertCircleIcon',
  iconVariant: 'danger',
  title: 'The instance is not updated',
  subtitle: 'Please have another try.',
  showFooter: false,
}

export default {
  namespaced: true,
  state: {
    instance: {
      styling: {
        primary: '#2BA7E0',
        secondary: '#7367F0',
        secondary_bg: '#8618F3',
        primary_button: '#C73CDE',
        secondary_button: '#CC63D6',
      },
      footer: {},
      hero: {},
      modules: [],
      payment_methods: [],
      oauth_providers: [],
      gender_settings: {
        use_gender: true,
        default_gender: 'Male',
      },
    },
    isLoading: false,
    saveInstanceModalData: {
      icon: '',
      title: '',
      subtitle: '',
      showFooter: false,
    },
    paymentMethodsList: [
      'Credit Card', 'Check', 'Cash', 'ACH', 'PayPal',
    ],
    reportsList: [],
    formsList: [],
  },
  getters: {
    getInstance: state => state.instance,
    getIsLoading: state => state.isLoading,
    getSaveInstanceModalData: state => state.saveInstanceModalData,
    getPaymentMethodsList: state => state.paymentMethodsList,
    getReportsList: state => state.reportsList,
    getFormsList: state => state.formsList,
  },
  mutations: {
    SET_INSTANCE: (state, val) => {
      state.instance = val
    },
    SET_IS_LOADING: (state, val) => {
      state.isLoading = val
    },
    SET_SAVE_INSTANCE_MODAL_DATA: (state, val) => {
      state.saveInstanceModalData = val
    },
    PREPARE_INSTANCE_DATA_FOR_SAVING: state => {
      imageFields.forEach(field => {
        if (!state.instance[field].includes('base64')) {
          // Remove image field if it's not a base64 file
          delete state.instance[field]
        }
      })
    },
    SET_REPORTS_LIST: (state, val) => {
      state.reportsList = val
    },
    SET_FORMS_LIST: (state, val) => {
      state.formsList = val
    },
  },
  actions: {
    async fetchInstancesList(ctx, queryParams) {
      try {
        return await axios.get('/auth/schools', { params: queryParams })
      } catch (error) {
        return error
      }
    },
    async fetchInstance({ commit }, instanceId) {
      try {
        commit('SET_IS_LOADING', true)
        const response = await axios.get(`/auth/schools/${instanceId}`)
        const instanceData = {
          ...response.data.data.config,
          oauth_providers: response.data.data.oauth_providers,
          report_ids: response.data.data.config.report_ids || [],
          name: response.data.data.name,
          logo: response.data.data.config.logo_url,
          favicon: response.data.data.config.favicon_url,
          hero_img: response.data.data.config.hero_img_url,
          footer_img: response.data.data.config.footer_img_url,
        }
        // Remove member_verification_error_message filed
        delete instanceData.member_verification_error_message

        commit('SET_INSTANCE', instanceData)
        return response
      } catch (error) {
        return error
      } finally {
        commit('SET_IS_LOADING', false)
      }
    },
    async createNewInstance({ state, commit }) {
      try {
        commit('SET_IS_LOADING', true)
        commit('SET_SAVE_INSTANCE_MODAL_DATA', loadingData)
        const response = await axios.post('/auth/schools/', state.instance)

        commit('SET_SAVE_INSTANCE_MODAL_DATA', successData)
        return response
      } catch (error) {
        commit('SET_SAVE_INSTANCE_MODAL_DATA', errorData)
        return error
      } finally {
        commit('SET_IS_LOADING', false)
      }
    },
    async updateInstance({ state, commit }, instanceId) {
      try {
        commit('SET_IS_LOADING', true)
        commit('SET_SAVE_INSTANCE_MODAL_DATA', loadingUpdateData)
        commit('PREPARE_INSTANCE_DATA_FOR_SAVING')
        const response = await axios.put(`/auth/schools/${instanceId}`, state.instance)

        commit('SET_SAVE_INSTANCE_MODAL_DATA', successUpdateData)
        return response
      } catch (error) {
        commit('SET_SAVE_INSTANCE_MODAL_DATA', errorUpdateData)
        return error
      } finally {
        commit('SET_IS_LOADING', false)
      }
    },
    async fetchReportsList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('auth/reports', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    async fetchFormsList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('auth/forms', {
            params: queryParams,
            headers: {
              WithoutSchoolFilter: true,
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    async fetchRegistrationFormsList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('auth/school-registration-forms', {
            params: queryParams,
            headers: {
              WithoutSchoolFilter: true,
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    async saveRegistrationFormsList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`auth/school-registration-forms/${queryParams.id}`, queryParams.data, {
            headers: {
              WithoutSchoolFilter: true,
            }
          })
          .then(response => {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: 'Registration forms were successfully saved',
                icon: 'AlertTriangleIcon',
                variant: 'info',
              },
            })
            resolve(response)
          })
          .catch(error => {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: "Error saving Registration forms",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            reject(error)
          })
      })
    },
  },
}
